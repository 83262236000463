<template>
    <div class="fixed w-[100vw] h-[100vh] bg-[#000000] bg-opacity-60 z-[1000] inset-0">
        <div
            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1003] w-[560px] h-[355px] rounded-[32px] bg-[#161618] opacity-100 flex flex-col items-center justify-center">
            <img src="@/assets/images/pop-gold.png" class="mt-[-250px] w-[320px] h-[320px]" />
            <div class="w-[440px]">
                <el-progress :stroke-width="12" :percentage="showProgress" color="#8771F4"
                    define-back-color="#979797" :show-text="false">
                </el-progress>
            </div>
            <div class="text-[28px] font-medium text-[#ffffff] opacity-70 mt-[36px]">Loading</div>
            <div class="text-[36px] text-[#B2F562] font-bold mt-[12px]">{{ showProgress }}%</div>
        </div>
    </div>
</template>
<style lang="scss"></style>
<script>
import FakeProgress from 'fake-progress';
export default {
    components: {
    },
    data() {
        return {
            progress: 0,
            fake: new FakeProgress({
                timeConstant: 5000,
                autoStart: true
            }),
            useFake: true
        }
    },
    computed: {
        showProgress() {
            if (this.useFake) {
                return parseInt(this.fake.progress * 100);
            } else {
                return parseInt(this.progress);
            }
        }
    },
    created() {

    },
    watch: {
        showProgress(val) {
            if (val >= 100) {
                this.$store.commit('setShowLoading', false);
            }
        }
    },
    created() {
        this.fake.start();
    },
    methods: {
        end(callback) {
            this.progress = parseInt(this.fake.progress * 100);
            this.fake.end();
            this.useFake = false;
            // 剩下的进度按1000ms，100%计算，平缓结束
            let startTime = new Date().getTime();
            let originProgress = this.progress;
            const animate = () => {
                let now = new Date().getTime();
                let interval = now - startTime;
                let changed = interval / 3000 * 100;
                let progress = changed + originProgress;
                if (progress >= 100) {
                    this.progress = 100;
                    try {
                        callback && callback();
                    } catch (e) {
                        console.log(e);
                    }
                    return;
                }
                this.progress = parseInt(progress);
                requestAnimationFrame(animate);
            }
            requestAnimationFrame(animate);
        },
        close(url) {
            this.end(() => {
                this.$router.push(url);
            });
        }
    },

}
</script>