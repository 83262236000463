import Vue from 'vue'

import Vuex from 'vuex'
import { getUserInfo } from '@/common/api';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showTabbar: true,
    selectedTabbarIndex: 0,
    tabbarConfig: [
      {
        activeImage: "assets/images/tabbar/home-active.png",
        deactiveImage: "assets/images/tabbar/home-deactive.png",
        route: "/",
        text: 'Home'
      },
      {
        text: "Leaderboard",
        activeImage: "assets/images/tabbar/leaderboard-active.png",
        deactiveImage: "assets/images/tabbar/leaderboard-deactive.png",
        route: "/leaderboard"
      },
      {
        text: "Friends",
        activeImage: "assets/images/tabbar/friends-active.png",
        deactiveImage: "assets/images/tabbar/friends-deactive.png",
        route: "/friends"
      }
    ],
    showLoading: false,
    tgUser: {

    },
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {
      id: 0,
      name: '',
      email: '',
      avatar: '',
      
    },
    rankData: localStorage.getItem('rankData') ? JSON.parse(localStorage.getItem('rankData')) : {
      rank: 0,
      exceed_percent: 0,
      total: 0,
      rankList: []
    },
    taskData: []
  },
  getters: {
  },
  mutations: {
    setSelectedTabbarIndex(state, index) {
      state.selectedTabbarIndex = index;
      state.showTabbar = true;
    },
    setShowTabbar(state, value) {
      state.showTabbar = value;
    },
    setShowLoading(state, value) {
      state.showLoading = value;
    },
    setTgUser(state, user) {
      state.tgUser = user;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
    },
    setRankData(state, data) {
      state.rankData = data;
      localStorage.setItem('rankData', JSON.stringify(data));
    },
    setTaskData(state, data) {
      state.taskData = data;
    },

  },
  actions: {
    async fetchUserInfo({ commit, state }) {
      let res = await getUserInfo(state.tgUser.id);
      let userInfo = {
        wallet_address: res.data?.user_date?.[0] || '',
        tg_id: res.data?.user_date?.[1] || '',
        tg_name: res.data?.user_date?.[2] || state?.tgUser?.last_name || '',
        invite_code: res.data?.user_date?.[3] || '',
        token_amount: res.data?.user_date?.[4] || 0,
        invited_users: res.data?.user_date?.[5] || 0,
        trade_count: res.data?.user_date?.[6] || 0,
        sol_trade_amount: res.data?.user_date?.[7] || 0
      }
      console.log(userInfo);
      commit("setUserInfo", userInfo);
      let taskStatus = {};
      res?.data?.taskprogress_data?.forEach(item => {
        taskStatus[item.task_id] = item.task_status;
      });
      let taskData = res.data?.tasks_data || [];
      for (let i = 0; i < taskData.length; i++) {
        taskData[i].status = taskStatus[taskData[i].task_id] || 0;
      }
      console.log(taskData);
      commit("setTaskData", taskData || []);
    } 
  },
  modules: {
  }
})
