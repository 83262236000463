<template>
    <div
        class="w-full box-border overflow-hidden pb-[100px] bg-[#000000] flex flex-col items-center justify-between f-full">
        <swiper class="w-full mt-[144px] swiper" :options="swiperOption">
            <div class="swiper-pagination" slot="pagination"></div>
            <swiper-slide>
                <div class="flex flex-col items-center justify-between">
                    <div class="flex flex-col">
                        <div class="text-[56px] font-bold">Rising Star!</div>
                        <div class="mt-[20px] text-[28px] font-medium text-[#8771F4]">You've joined Mole</div>
                        <div class="overflow-hidden relative friends_bg w-full">
                            <img src="@/assets/images/friends-bg.png" class="w-[750px] h-[750px]">
                        </div>
                        <div class="mt-[-140px] relative z-30 text-[58px] font-bold">Now start digging, Mole.</div>
                    </div>
                    <div class="mt-[150px] text-[#FFFFFF] text-opacity-70 font-medium text-[28px] tracking-wide">
                        <div v-if="userInfo.ID">Your account number is <span class="text-[#B2F562] text-opacity-100">#{{
                            userInfo.ID }}</span></div>
                        <div>You're in the Top <span class="text-[#B2F562] text-opacity-100">{{ rankData.exceed_percent }}%
                            </span>Mole users</div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="flex flex-col items-center justify-between">
                    <div class="flex flex-col">
                        <div class="text-[56px] font-bold">You are amazing!</div>
                        <div class="mt-[20px] text-[28px] font-medium text-[#8771F4]">Here is your Mile reward</div>
                        <div class="overflow-hidden relative friends_bg w-full">
                            <img src="@/assets/images/friends-bg.png" class="w-[750px] h-[650px]">
                        </div>
                        <div class="mt-[-140px] relative z-30 text-[58px] tracking-wider font-bold">{{
                            userInfo.token_amount.toLocaleString() }}</div>
                    </div>
                    <!-- <div class="mt-[150px] text-[#FFFFFF] text-opacity-70 font-medium text-[28px] tracking-wide">
                        <div>Thanks for your time on Mole.</div>
                    </div> -->
                </div>
            </swiper-slide>
        </swiper>
        <div class="flex-1 w-[686px] h-[100px]">
        </div>
        <div @click="handleContinueClick"
            class="z-50 fixed bottom-[60px] cursor-pointer text-[#ffffff] font-bold text-[32px] leading-[89px] w-[686px] h-[89px] bg-[#8771F4] rounded-[32px]">
            Continue
        </div>
    </div>
</template>
<style lang="scss">
.friends_bg {
    &:after {
        content: '';
        position: absolute;
        left: -80px;
        top: -60px;
        width: 671px;
        height: 671px;
        opacity: 0.41;
        background: radial-gradient(48.58% 44.71% at 50.074515648286145% 50.074515648286145%, rgba(135, 113, 244, 1) 0%, rgba(135, 113, 244, 0) 100%);
    }

    &::before {
        position: absolute;
        content: '';
        left: 90px;
        top: -60px;
        width: 671px;
        height: 671px;
        opacity: 0.24;
        background: radial-gradient(48.58% 44.71% at 50.074515648286145% 50.074515648286145%, rgba(178, 245, 98, 1) 0%, rgba(178, 245, 98, 0) 100%);
    }
}

.swiper {
    overflow: visible !important;

    .swiper-pagination {
        top: -30px;
        z-index: 100;
    }

    .swiper-pagination-bullet-custom {
        width: 128px;
        height: 6px;
        border-radius: 4px;
        transform: translateY(-20px);
        background: rgba(255, 255, 255, 1);
        z-index: 100;

        &:hover {
            opacity: 1;
        }

        &.swiper-pagination-bullet-active {
            opacity: 0.7;
        }
    }
}
</style>
<script>
import { mapState } from 'vuex';
export default {
    components: {
    },
    data() {
        return {
            swiperOption: {
                spaceBetween: 30,
                centeredSlides: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                    stopOnLastSlide: true,
                    // reverseDirection: true
                },
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet(index, className) {
                        return `<div class="${className} swiper-pagination-bullet-custom"></div>`
                    }
                }
            }

        }
    },
    computed: {
        viewportHeight() {
            return window?.innerHeight || 1100;
        },
        ...mapState(['userInfo', 'rankData'])
    },
    created() {
        this.$store.commit("setShowTabbar", false);
    },
    methods: {
        handleContinueClick() {
            localStorage.setItem('isFirstLogin', false);
            this.$router.push('/');
        }
    },

}
</script>