<template>
    <div 
      @touchstart="handleTouchStart" 
      @touchend="handleTouchEnd" 
      @mouseover="handleMouseOver" 
      @mouseleave="handleMouseLeave" 
      class="h_button" 
      :class="{'active': isActive}"
    >
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MyButton',
    data() {
      return {
        isActive: false,
        isTouch: false
      }
    },
    methods: {
      handleTouchStart() {
        this.isTouch = true;
        this.isActive = true;
      },
      handleTouchEnd() {
        this.isActive = false;
      },
      handleMouseOver() {
        if (!this.isTouch) {
          this.isActive = true;
        }
      },
      handleMouseLeave() {
        if (!this.isTouch) {
          this.isActive = false;
        }
      }
    }
  }
  </script>
  
  <style lang="scss">
  .h_button {
    cursor: pointer;
    transition: opacity 0.3s;
    user-select: none;
    &.active {
        opacity: 0.7;
    }
  }
  </style>
  