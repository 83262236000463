<template>
    <div class="w-full h-full box-border px-[40px] py-[50px] flex flex-col items-center overflow-hidden">
        <div class="text-title mt-[50px] w-[442px]">Invite friends and get more MOLE</div>
        <div class="mt-[40px] flex flex-col">
            <img src="@/assets/images/friends-bg.png" class="w-[576px] h-[576px]" />
            <span class="text-[#8771F4] text-[28px] font-medium mt-[-50px]">Tap on the button to invite your friends</span>
        </div>
        <my-button>
            <div class="mt-[137px] cursor-pointer bg-[#B2F562] text-[#000000] font-bold text-[32px] w-[560px] h-[89px] leading-[89px] rounded-[32px]"
                @click="handleCopyInvite">
                Invite Friends
            </div>
        </my-button>
    </div>
</template>
  
<script>
import MyButton from '@/components/MyButton';
// @ is an alias to /src

import Vue from 'vue';
export default {
    components: {
        MyButton
    },
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit('setSelectedTabbarIndex', 2);
    },
    methods: {
        handleCopyInvite() {
            const utils = this.$tg_utils;
            console.log(utils);
            let text_to_copy = "https://t.me/sol_pvp_test_bot?start=invite-" + this.$store.state.userInfo.invite_code;


            let input = document.createElement('input');
            input.setAttribute('readonly', 'readonly');
            input.setAttribute('value', text_to_copy);
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
                alert('Invite Link has been copied successfully');
            }
            document.body.removeChild(input);
            if (utils?.shareURL) {
                utils.shareURL(text_to_copy, 'Look! I found a great app to earn money! Join me now!');
                return;
            }
        }
    }
}
</script>
  
<style lang="scss"></style>