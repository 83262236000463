import Vue from 'vue'
import VueRouter from 'vue-router'
import FriendsView from '../views/FriendsView.vue'
import Invition from '../views/Invition.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
    }
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/LeaderboardView.vue')
    }
  },
  {
    path: '/friends',
    name: 'friends',
    component: FriendsView
  },
  {
    path: '/test',
    name: 'test',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/TestView.vue')
    }
  },
  {
    path: '/invition',
    name: 'invition',
    component: Invition
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
