<template>
  <div id="app">
    <router-view />
    <tabbar v-if="showTabbar"></tabbar>
    <loading ref="loading" v-if="showLoading"></loading>
  </div>
</template>

<style lang="scss">
#app {
  font-family: 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #000000;
  width: 750px;
  max-width: 750px;
  overflow: hidden;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 130px;
  color: #FFFFFF;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';
import Loading from '@/components/Loading';
import { getRankData, getUserInfo } from '@/common/api';
import Vue from 'vue';
export default {
  name: 'App',
  components: {
    Tabbar: () => import('@/components/Tabbar'),
    Loading
  },
  computed: {
    ...mapState(['showTabbar', 'showLoading', 'userInfo'])
  },

  
  async mounted() {
    let utils = null;
    try {
      const initUtils = require('@telegram-apps/sdk').initUtils;
      utils = initUtils();
      console.log("utils:", utils);
      Vue.prototype.$tg_utils = utils;
    } catch (e) {
      console.error("Error in initUtils", e);
    }
    const tg = window.Telegram.WebApp;
    const user = tg.initDataUnsafe.user;
    console.log("tg:", tg);
    console.log("tgUser:", user);
    if (user) {
      this.$store.commit('setTgUser', user);
    } else {
      this.$store.commit('setTgUser', null);
    }
    

    this.$store.commit('setShowTabbar', true);
    let isFirstLogin = !localStorage.getItem("lastLogin");

    if (isFirstLogin) {
      this.$store.commit("setShowLoading", true);
    }
    await this.fetchData();
    if (isFirstLogin && user) {
      this?.$refs?.loading?.close("/invition");
      return;
    }
  },
  methods: {
    async fetchData() {
      let promises = [this.fetchUserInfo(), this.fetchRankInfo()];
      try {
        await Promise.all(promises);
      } catch (e) {
        console.error(e);
      }
      localStorage.setItem("lastLogin", new Date().getTime());
    },
    async fetchRankInfo() {
      let res = await getRankData(this.$store.state.tgUser.id);
      let exceed_percent = 0;
      let rankData = {
        rank: res.data?.user_rank,
        rankList: res.data?.top_users,
        total: res.data?.total_users,
        exceed_percent
      };
      this.$store.commit("setRankData", rankData);

    },
    async fetchUserInfo() {
      let res = await getUserInfo(this.$store.state.tgUser.id);
      //let res = await getUserInfo(5710061760);
      let userInfo = {
        wallet_address: res.data?.user_date[0]?.sol_wallet || '',
        tg_id: res.data?.user_date[0]?.tg_id || '',
        tg_name: res.data?.user_date[0]?.tg_name || this.$store.state?.tgUser?.last_name || '',
        invite_code: res.data?.user_date[0]?.invite || '',
        token_amount: res.data?.user_date[0]?.token_amount || 0,
        invited_users: res.data?.user_date[0]?.friend || 0,
        trade_count: res.data?.user_date[0]?.tx || 0,
        sol_trade_amount: res.data?.user_date[0]?.sol_total || 0
      }
      console.log('====================================',userInfo);
      this.$store.commit("setUserInfo", userInfo);
      let taskStatus = {};
      res?.data?.taskprogress_data?.forEach(item => {
        taskStatus[item.task_id] = item.task_status;
      });
      let taskData = res.data?.tasks_data || [];
      for (let i = 0; i < taskData.length; i++) {
        taskData[i].status = taskStatus[taskData[i].task_id] || 0;
      }
      console.log(taskData);
      this.$store.commit("setTaskData", taskData || []);
    },
  }

}

</script>


